// import { getCommonParamsOptions } from '@/utils/common.js'

export const baseFormConfig = {
  formItems: [
    // {
    //   field: 'online',
    //   type: 'input',
    //   label: 'Online Status',
    //   otherOptions: {
    //     disabled: true
    //   }
    // },
    {
      field: 'sn',
      type: 'input',
      label: 'terminal.sn',
      otherOptions: {
        disabled: true
      }
    },
    // {
    //   field: 'tenantName',
    //   type: 'input',
    //   label: 'file.tenant',
    //   otherOptions: {
    //     disabled: true
    //   }
    // },
    // {
    //   field: 'merchantName',
    //   type: 'input',
    //   label: 'common.app-merchant-name',
    //   otherOptions: {
    //     disabled: true
    //   }
    // },
    // {
    //   field: 'modelName',
    //   type: 'input',
    //   label: 'file.model-type',
    //   otherOptions: {
    //     disabled: true
    //   }
    // },
    {
      field: 'networkType',
      type: 'input',
      label: 'Network Type',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'networkSignal',
      type: 'input',
      label: 'Network Signal',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'totalMemory',
      type: 'input',
      label: 'Total DDR Memory',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'availableMemory',
      type: 'input',
      label: 'Available DDR Memory',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'memoryUtilization',
      type: 'input',
      label: 'Memory Utilization (%)',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'totalDisk',
      type: 'input',
      label: 'Total Flash',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'availableDisk',
      type: 'input',
      label: 'Available Flash',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'diskUtilization',
      type: 'input',
      label: 'Disk Utilization (%)',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'batteryInfo',
      type: 'input',
      label: 'Battery Percentage (%)',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'longitude',
      type: 'input',
      label: 'monitor.longitude',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'latitude',
      type: 'input',
      label: 'monitor.latitude',
      otherOptions: {
        disabled: true
      }
    },
    // {
    //   field: 'status',
    //   type: 'select',
    //   label: 'general.status',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getCommonParamsOptions('terminal_status')
    // },
    {
      field: 'updatedTime',
      type: 'input',
      label: 'Last Report Time',
      otherOptions: {
        disabled: true
      }
    }
  ]
}
