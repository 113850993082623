<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      pageType="view"
    >
      <template #customForm>
        <!-- <el-col :span="8" style="padding: 18px">
          <el-form-item label="Memory(MB)" class="form-item">
            <div class="status-wrap" v-if="pageInfo.online">
              <div class="circle online-circle"></div>
              Online
            </div>
            <div class="status-wrap" v-else>
              <div class="circle offline-circle"></div>
              Offline
            </div>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :span="8" style="padding: 18px">
          <el-form-item label="Memory(MB)" class="form-item">
            <el-progress
              class="memory-progress"
              type="circle"
              :percentage="getMemoryInfo(pageInfo.memoryInfo)"
            >
              <template #default="{ percentage }">
                <span class="percentage-value">{{ percentage }}%</span>
                <span class="percentage-label">{{ pageInfo.memoryInfo }}</span>
              </template>
            </el-progress>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="padding: 18px">
          <el-form-item label="Current Battery" class="form-item">
            <el-progress
              style="width: 150px"
              :text-inside="true"
              :stroke-width="26"
              :percentage="pageInfo.batteryInfo"
            ></el-progress>
          </el-form-item>
        </el-col> -->
      </template>
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/view.js'
import { useStore } from 'vuex'
import { ref } from 'vue'
import i18n from '@/i18n'
import { useRoute } from 'vue-router'
import { cloneDeep } from 'lodash'
import { getCurrentSystemTimeFormat } from '@/utils/util.js'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global
const route = useRoute()
const sn = route.params.sn
const pageInfo = ref({})
pageTitle.value = t('general.router-monitor-status-detail')

const store = useStore()

// const batteryInfo = computed(() => {
//   return (battery) => {
//     if (battery) {
//       const all = battery.split('/')[1]
//       const current = battery.split('/')[0]
//       return (all / current).toFixed(2)
//     } else {
//       return '0'
//     }
//   }
// })

// const getMemoryInfo = (memory) => {
//   console.log(memory, 'memory')
//   if (memory) {
//     const all = memory.split('/')[1]
//     const current = memory.split('/')[0]
//     return ((current / all) * 100).toFixed(2)
//   } else {
//     return '0'
//   }
// }
const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值
  const data = await store.dispatch('monitor/getMonitorStatusDetailBySn', sn)
  const detailInfo = cloneDeep(data)
  detailInfo.updatedTime = getCurrentSystemTimeFormat(detailInfo.updatedTime)
  pageInfo.value = detailInfo
}

getCurrentPageInfo()
</script>

<style lang="scss" scoped>
.status-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}
.online-circle {
  background-color: green;
}
.offline-circle {
  background-color: grey;
}

.percentage-label {
  display: block;
  font-size: 12px;
  margin-top: 10px;
}
</style>
